@use '../lib';

.link{
  line-height: 0;
  outline: none;
}

.logo{
    @include lib.mq($until: 'laptop') {
        padding: 12px !important;
        transform: translateX(-11px);
    }
}