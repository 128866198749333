@use '../lib';

.app{
    background: lib.color('secondaryTint1');
    color: lib.color('text');
    overflow: hidden;
}

.startPageMain{
    margin: 0 auto;
    padding: 1.25rem 0 0;

    @include lib.mq($from: tablet) {
      padding: 2.5rem 2rem;
    }

    @include lib.mq($from: laptop) {
      padding: 4rem 2rem 1.25rem;
      max-width: 58.84375rem;
    }
}

.startPageHead{
    @include lib.heading4();
    text-align: center;
    margin: 1.25rem auto 2.5rem;

    @include lib.mq($from: tablet) {
      margin-bottom: 3.75rem;
    }

    @include lib.mq($from: desktop) {
      margin-bottom: 5rem;
    }
}