@use '../lib';

.headerHamburger{
    @include lib.align();
    // max-width: 1110px;
    color: lib.color("text");
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--inverted {
        color: #fff;
    }

    &--open {
        @include lib.mq($until: "tablet") {
        color: lib.color("text");
        }
    }

    &__navigation {
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    
        &.header-navigation-enter-active,
        &.header-navigation-leave-active {
          transition: opacity 0.25s;
        }
    
        &.header-navigation-enter,
        &.header-navigation-leave-to {
          opacity: 0;
        }
    
        &.header-navigation-leave-to {
          transition-duration: 0.125s;
        }
      }
}