@use '../lib';

.navigation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 5.625rem 1.25rem 4.125rem;
    // background: lib.color('secondary2');
    background: rgba(#000, 0.7);
    overflow-y: scroll;
  
    @include lib.headerNavAlign(); 
  
    &__navigationHolder {
      @include lib.mq($from: 'tablet') {
        @include lib.align();
        display: flex;
        flex-direction: column;
      }
  
      &--newAlign {
        @include lib.mq($from: 'tablet') {
          max-width: 1110px;
        }
      }
    }
  
    &__list {
      &--subLink {
        margin-top: 1.55rem;
      }
  
      &--buttonGroup {
        margin-top: 1.875rem;
      }
  
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
  
      @include lib.mq($from: 'tablet') {
        width: 40%;
        align-self: flex-end;
      }
  
      @include lib.mq($from: 'laptop') {
        width: 30%;
      }
    }
  
    &__item {
      &:nth-child(n + 2) {
        margin-top: 0.725rem;
      }
  
      &--mainLink {
        &:nth-child(n + 2) {
          margin-top: 1rem;
        }
      }
  
      &--groupItem {
        &:nth-child(n + 2) {
          margin-top: 0;
          padding-top: 0.9375rem;
        }
      }
  
      .btn {
        min-width: 260px;
      }

    }
  
    &__link {
      @include lib.heading2();
  
      &--subLink {
        @include lib.bodyText();
        font-weight: normal;
      }
  
      &--login {
        &:hover {
          border-color: #eae7e7;
          -webkit-transition: border-color 0.3s ease;
          transition: border-color 0.3s ease;
        }
        border-color: #e5d2d340;
        padding: 0.9rem;
      }
  
      &--active {
        color: color('primary');
      }
  
      &:hover {
        color: color('primary');
      }
  
      border: 0;
      padding: 0;
      cursor: pointer;
      display: block;
      color: lib.color('secondaryTint1');
      background: transparent;
      box-shadow: none;
  
      &:active,
      &:focus {
        outline: none;
      }
  
      @include lib.mq($from: 'laptop') {
        font-size: 30px;
      }
    }
  
    &__subLink {
      &:hover {
        color: lib.color('primary');
      }
      color: lib.color('secondaryTint1');
      cursor: pointer;
    }
  
    &__button {
      height: 0;
  
      @include lib.mq($from: 'tablet') {
        display: none;
      }
    }
  
    // &--homePageOnly {
    //   &__navigation__navigationHolder {
    //     max-width: 1480px;
    //   }
    // }
  }
  