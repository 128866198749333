@use '../lib';

.card {
  position: relative;

  &__holder{
    max-width: 45.625rem;
    margin: 0 auto;
    padding: 2.25rem 1.25rem;

    @include lib.mq($from: 'tablet') {
      padding: 1.75rem 2rem 3.75rem;
    }
  }

  &__item{
    // background: linear-gradient(180deg, #F8F3F1 0%, #F3F1EF 100%);
    background : #F3F1EF;
    border-radius: 10px;
    padding: 1.25rem 1rem;
    // margin-bottom: 0.625rem;
    margin: 0 auto 0.625rem;
    width: 100%; // using in Card coponent as well

    transition: 0.5s;

    @include lib.mq($from: 'tablet') {
      padding: 1.55rem;
      margin-bottom: 1.25rem;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &:hover{
      // background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      background : #fff;
    }

      @for $i from 1 through lib.$columns{        
        &--#{$i}{
            max-width: lib.wrapperMaxWidth(12);
            @include lib.mq($from: 'tablet') {
                max-width: lib.wrapperMaxWidth($i);
            }                
        }
    }
  }

  &__cover{
    background : #F3F1EF;
    border-radius: 10px;
    padding: 1.25rem 1rem;
    // margin-bottom: 0.625rem;
    margin: 0 auto 0.625rem;
    width: 100%; // using in Card coponent as well

    transition: 0.5s;

    @include lib.mq($from: 'tablet') {
      padding: 1.55rem;
      margin-bottom: 1.25rem;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &:hover{
      // background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      background : #fff;
    }
  }

  &__date{
    @include lib.bodyMetaMini(false, rgba(lib.color('heading'), 0.5));
  }

  &__title{
    @include lib.heading5();
    margin-top: 0.625rem;
    
    @include lib.mq($from: 'laptop') {
      max-width: 37.5rem;
    }
  }

  &__cta{
    min-width: auto;
    @include lib.bodyMetaMini(false, lib.color('secondaryTint1'));
    padding: 0.425rem 1rem;
  }

  &__links{
    margin-top: 1.25rem;
    @include lib.mq($from: 'tablet') {
      margin-top: 1.875rem;
    }
  }

  &__linkItem{
    @include lib.bodyMetaMini(false, lib.color('primary'));
    margin-bottom: 0.425rem;
    display: flex;
    @include lib.mq($from: 'tablet') {
      margin-bottom: 0.625rem;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &::before{
      content: "";
      background: url("#{lib.$assetPath}/images/shared/icon-link.svg") no-repeat;
      width: 13px;
      height: 10px;
      // display: inline-block;
      padding-right: 0.425rem;
      flex: 0 0 13px;
    }

    &--download{
      &::before{
        background: url("#{lib.$assetPath}/images/shared/icon-download.svg") no-repeat;
        width: 13px;
        height: 11px;
      }
    }
  }

  &__linkText{
    flex: 0 0 90%;
  }

  &__video{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-top: 1.875rem;
    border-radius: 6px;

    & > iframe,
    & > object,
    & > embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--reportCard{
    width: 100%;
  }

}
