@use '../lib';

.btn {
    font-weight: bold;
    font-size: 14px;
    line-height: 1rem;
    letter-spacing: 1px;

    position: relative;
    display: inline-block;
    text-decoration: none;
    margin: 0;
    min-width: 200px;
    text-align: center;

    // min-height: 2.875rem;
    min-height: 2rem;
    // padding: 0.9375rem 1.75rem 0.75rem;
    // padding: 0.875rem 1.75rem 0.75rem;
    // padding: 0.85rem 1.75rem 0.65rem;
    padding: 0.85rem 1.75rem .75rem;

    cursor: pointer;
    border-width: 2px;
    border-style: solid;
    appearance: none;
    border-radius: 3em;
    box-sizing: border-box;

    white-space: nowrap;
    box-shadow: 0 0 0;
    text-shadow: none;
    vertical-align: top;
    // text-transform: uppercase;
    // letter-spacing: 0.055em;
    text-transform: lowercase;
    letter-spacing: 0.005em;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn:first-letter{
  text-transform: capitalize
}

.btn--primary {
    color: lib.color('secondaryTint1');
    background: lib.color('primary');
    border-color: lib.color('primary');
    transition: opacity 0.3s ease, color 0.15s ease, border 0.3s ease,
      background 0.3s ease;

    svg {
      fill: color('secondaryTint1');
    }

    @include lib.mq($from: 'tablet') {
      &:hover {
        color: lib.color('secondaryTint1');
        background: lib.color('primaryHover');
        border-color: lib.color('primaryHover');
      }
    }
}

// Outlined secondary
.btn--secondary {
  color: lib.color('text');
  background: transparent;
  border-color: lib.color('border');
  transition: opacity 0.3s ease, color 0.15s ease, border 0.3s ease,
    background 0.3s ease;

  svg {
    fill: lib.color('text');
  }

  @include lib.mq($from: 'tablet') {
    &:hover {
      color: lib.color('text');
      background: transparent;
      border-color: lib.color('borderHover');
    }
  }

  &.btn--loading {
    &:after {
      opacity: 1;
      border-top-color: rgba(lib.color('text'), 0);
      border-right-color: rgba(lib.color('text'), 0.1);
      border-bottom-color: rgba(lib.color('text'), 0.3);
      border-left-color: rgba(lib.color('text'), 0.6);
    }
  }
}


// Outlined tertiary
.btn--tertiary {
  color: lib.color('text');
  // font-size: 16px;
  // letter-spacing: 0.1px;  
  background: rgba(0, 0, 0, 0.07);
  border: 2px solid rgb(0 0 0 / 0%);

  transition: opacity 0.3s ease, color 0.15s ease, border 0.3s ease,
    background 0.3s ease;

  svg {
    fill: lib.color('text');
  }

  @include lib.mq($from: 'tablet') {
    &:hover {
      color: lib.color('text');
      background: rgba(0, 0, 0, 0.16);
    }
  }

  &.btn--loading {
    &:after {
      opacity: 1;
      border-top-color: rgba(lib.color('text'), 0);
      border-right-color: rgba(lib.color('text'), 0.1);
      border-bottom-color: rgba(lib.color('text'), 0.3);
      border-left-color: rgba(lib.color('text'), 0.6);
    }
  }
  
}

  .btn--noTransform{
    text-transform: none;
  }

  // Play icon
  .btn--iconPlay,
  .btn--iconPause {
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      display: inline-block;
      margin: -4px 0.75em -4px 0;
      background: center center no-repeat;
      background-size: 18px 18px;
    }
  }

  .btn--iconPlay:before {
    background-image: url('#{lib.$assetPath}/images/shared/icon-play.png');
  }

  .btn--iconPause:before {
    background-image: url('#{lib.$assetPath}/images/shared/icon-pause.png');
  }

  .btn--secondaryLight {
    color: lib.color('secondaryTint1');
    background: transparent;
    border-color: lib.color('secondaryLight');
    transition: opacity 0.3s ease, color 0.15s ease, border 0.3s ease,
      background 0.3s ease;

    svg {
      fill: lib.color('text');
    }

    @include lib.mq($from: 'tablet') {
      &:hover {
        color: lib.color('secondaryTint1');
        background: transparent;
        border-color: lib.color('secondaryLightHover');
      }
    }

    &.btn--loading {
      &:after {
        opacity: 1;
        border-top-color: rgba(lib.color('text'), 0);
        border-right-color: rgba(lib.color('text'), 0.1);
        border-bottom-color: rgba(lib.color('text'), 0.3);
        border-left-color: rgba(lib.color('text'), 0.6);
      }
    }
  }

  .btn--circle {
    min-width: auto;
    min-height: auto;
    padding: 13px 18px;

    svg {
      position: relative;
      top: 2px;
    }
  }

  .btn--loading {
    cursor: default;
    pointer-events: none;
    color: rgba(lib.color("text"), 0);

    &::selection {
      background: transparent;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.25rem;
      height: 1.25rem;
      margin-top: 1.25rem * -0.5;
      margin-left: 1.25rem * -0.5;
      opacity: 0;
      transition: opacity 0.3s ease;
      border: 2px solid transparent;
      border-top-color: rgba(#fff, 0);
      border-right-color: rgba(#fff, 0.3);
      border-bottom-color: rgba(#fff, 0.6);
      border-left-color: rgba(#fff, 1);
      border-radius: 50%;
      animation: btn-loading 0.25s infinite linear;
    }

    @keyframes btn-loading {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  // Disabled
  .btn--disabled {
    cursor: default;

    svg {
      opacity: 0.1;
    }
  }

  .btn--navbuttons{
    min-width: 260px;
  }

  .btn--wide{
    width: 100%;
}

.btn--mb16{
  margin-bottom: 1rem;
}