@use '../lib';

.hamburger {

    &--open {
        &--first {
          -webkit-transform: rotate(-45deg) translate(-9px, 6px);
          transform: rotate(-45deg) translate(-9px, 6px);
        }
    
        &--second {
          opacity: 0;
        }
    
        &--third {
          -webkit-transform: rotate(45deg) translate(-8px, -8px);
          transform: rotate(45deg) translate(-8px, -8px);
        }
                
        @include lib.mq($from: 'desktop') {            
          margin-right: 0.5rem;        
        }
      }
  
    display: inline-block;
    cursor: pointer;
  
    top: -1px;
    width: 1.275rem;
    height: 1.75rem;
    color: inherit;
    z-index: 2;
    position: relative;

    &__userlogin{
        @include lib.bodySansMedium();
        margin-top: 4px;
        color: lib.color('secondaryTint1');
        margin-right: 0.625rem;
        @include lib.mq($until: "tablet") {
          display: none;
        }

      + svg {
        cursor: pointer;
      }

      + svg path {
        fill: lib.color("secondaryTint1");
      }
  
      &--noBg {
        color: lib.color("text");
  
        + svg path {
          fill: lib.color("text");
        }
      }
  
      &--bg {
        color: lib.color("secondaryTint1");
  
        + svg path {
          fill: lib.color("secondaryTint1");
        }
      }
  
      &--registeredUser {
        color: lib.color("primary");
  
        + svg path {
          fill: lib.color("primary");
        }
      }
  
      &--light {
        color: lib.color("body");
  
        + svg path {
          fill: lib.color("body");
        }
      }
  
      &--user {
        color: lib.color("primary");
      }
    }

    &__userSection {
      display: flex;
      align-items: center;
    }
    
    &__userSectionDivider{
        margin-right: .925rem;
        padding-left: .825rem;
        color: #a1a1a1;
    }

    &__login{
      visibility: visible; // added temporarily to remove the console warning 

      &--noBg{
        visibility: visible; // added temporarily to remove the console warning 
      }

      &--bg{
        visibility: visible; // added temporarily to remove the console warning
      }
    }

    &__bar{
        width: 1.3rem;
        height: 2px;
        background-color: lib.color('secondaryTint1');
        margin: 6px 0;
        transition: 0.4s;
        display: block;        

        &--noBg,
        &--registeredUser{
            background-color: lib.color("text");
        }

        &--first {
            -webkit-transform: rotate(-45deg) translate(-4px,6px);
            transform: rotate(-45deg) translate(-4px,6px);
        }

        &--second {
            opacity: 0;
        }

        &--third {
            -webkit-transform: rotate(45deg) translate(-5px,-7.8px);
            transform: rotate(45deg) translate(-5px,-7.8px);
        }

        &--bg{
          visibility: visible; // added temporarily to remove the console warning
        }
    }


    &__hamburger{
        &--open .bar--first {
            -webkit-transform: rotate(-45deg) translate(-4px,6px);
            transform: rotate(-45deg) translate(-4px,6px);
        }

        &--open .bar--second {
            opacity: 0;
        }

        &--open .bar--third {
            -webkit-transform: rotate(45deg) translate(-5px,-7.8px);
            transform: rotate(45deg) translate(-5px,-7.8px);
        }
        
    }

&__navigation{
    visibility: hidden;

    &--open{
        visibility: visible;
    }
}


    &__searchMobile {
        display: flex;
        align-items: center;
        position: relative;
        left: 1rem;    

        &--open{
            visibility: visible;
            z-index: 3;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

  &__searchMobileHolder--open {    
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 1.25rem 1.25rem 4.125rem;
      background: #eeebe8;
      overflow-y: scroll;    
  }

  &__search {
    display: flex;
    align-items: center;
    position: relative;
    left: 1rem;     

    &--open{
      visibility: visible; // temperory added just to remove console warning
    }

    &--mobile{
        left: -2rem;
    }

    &--openMobile{
        left: -1rem;
    }
  }

    &__searchIconHolder {
        display: none;
        position: relative;
        left: 1rem;
        z-index: 1;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;        

        @include lib.mq($from: "tablet") {
        display: inline-flex;
        }

        &--open{
          left: 2rem;
        }
      
        &--mobile{
          display: inline-flex;
          @include lib.mq($from: "tablet") {
            display: none;
          }
        }

        &--undefined{
          visibility: visible; // temperory added just to remove console warning
        }
    }

  

  &__searchIcon {
    &--dark,
    &--noBg,
    &--open{
      visibility: visible;
      path {
          fill: #555;
      }
  }

    &--open{
        path {
            fill: #333;
            }
        }
  }

    &__searchField {
    @include lib.bodySansMedium();
    color: lib.color('text');
    visibility: hidden;
    transition: all 350ms;
    float: right;
    background: none;
    border: none;
    padding: 0;
    width: 36px;  

    position: relative;
    margin: 0;
    border-radius: 0.25rem;
    appearance: none;
    white-space: nowrap;
    vertical-align: middle;
    
    &:focus{
        border: none;
      }

    &::placeholder{
      visibility: hidden;
    }

    &--open {
      visibility: visible;
      padding: 0.925rem 2.25rem 0.625rem 2.5rem;
      background: lib.color("body");

      @include lib.mq($from: "tablet") {
        width: 240px;
      }

      @include lib.mq($from: "laptop") {
        width: 311px;
      }

      &::placeholder{
        visibility: visible;
      }
    }    

    &:focus,
    &:active{
      border: none;
      outline: none;
    }
  }  

  &__searchFieldMobile {
    @include lib.bodySansMedium();
    color: lib.color('text');
    display: none;
    float: right;
    background: transparent;
    border: none;
    padding: 0;
    width: 36px;
  }

  &__searchFieldMobile--open {
    display: block;
    width: 100%;
    padding: 0.925rem 1.775rem 0.625rem 2.5rem;
    background: lib.color("body");
  }

  &__searchSuggestions {
    position: absolute;
    top: 3rem;
    left: 1.1rem;
    padding: 0;
    background: #ffffff;
    border-radius: 5px;
    max-height: 300px;
    overflow: auto;

    margin: 0;
    padding: 0;

    @include lib.mq($until: "tablet") {
      background: transparent;
      width: 97%;
      max-height: calc(100vh - 2.5rem);
    }

    @include lib.mq($from: "tablet") {
      width: 240px;
    }

    @include lib.mq($from: "laptop") {
      width: 311px;
    }

    &--didyoumean{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      + .hamburger__searchSuggestions{
        top: 5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

  }

  &__searchSuggestionsItems {
    @include lib.bodyText();
    list-style: none;
    padding: 0.325rem 1rem;    
    cursor: pointer;

    @include lib.mq($until: "tablet") {
      padding: 0.325rem 0;
    }

    &--didyoumean{
      @include lib.bodyMeta();
      font-size: 0.813rem;
      opacity: 0.5;      
      cursor: auto;

    &:last-child,
    &:only-child {
      padding-bottom: 0.825rem;
    }

    &:first-child,
    &:only-child {
      padding-top: 0.725rem;
    }

    }

    &:last-child,
    &:only-child {
      padding-bottom: 0.625rem;
    }

    &:first-child,
    &:only-child {
      padding-top: 0.625rem;
    }

    &:hover {
      background: rgba(85, 85, 85, 0.1);
    }

    &--among{
      @include lib.bodyMeta();
      font-size: 0.813rem;
      opacity: 0.5;      
      cursor: auto;
    }
  }

    &__searchReset {
    background: transparent;
    border: navajowhite;
    position: absolute;
    right: 10px;
    top: 15px;
  }

  &__searchCancel {
    @include lib.bodySansMedium();
    color: lib.color('text');
    display: none;
    padding-left: 1rem;

    &--open {
        display: block;    
    }
  }

  &__searchInput {
    position: relative;
    width: 100%;
  }

  &__searchSuggestionsLink {
    display: block;
  }

}

